import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import PropTypes from "prop-types";

const BlogItem = ({ post }) => {
  const {
    excerpt,
    fields: { slug, prefix },
    frontmatter: { title, date, cover }
  } = post;

  const fluid = cover && cover.childImageSharp && cover.childImageSharp.fluid;

  return (
    <React.Fragment>
      <li className="post-item">
        <Link to={slug} key={slug} className="post-link">
          <span className="post-time">{date || prefix}</span>

          <span className="post-description-wrap">
            <span className="post-title">{title}</span>

            <span className="post-description">{excerpt}</span>
          </span>

          <Img className="post-image" fluid={fluid} />
        </Link>
      </li>

      {/* --- STYLES --- */}
      <style jsx>{`
        .post-item :global(.post-link:hover) {
          .post-title {
            color: #0005fb;
          }
        }

        .post-item:not(:last-child) {
          padding-bottom: 50px;
          margin-bottom: 50px;
          border-bottom: 2px solid #222;
        }

        .post-hr {
          margin: 50px 0;
        }

        .post-description-wrap {
          display: grid;
        }

        .post-time {
          font-family: Neutrif Pro;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 1.3;
          color: #0005fb;
          text-transform: uppercase;
        }

        .post-title {
          font-family: Neutrif Pro;
          font-style: normal;
          font-weight: 500;
          line-height: 1.1;
          letter-spacing: -0.02em;
          color: #141418;
        }

        .post-description {
          font-family: Neutrif Pro;
          font-style: normal;
          font-weight: normal;
          line-height: 1.4;
          color: #141418;
        }

        @media screen and (min-width: 320px) {
          .post-title {
            font-size: 24px;
            margin-bottom: 12px;
          }
          .post-description {
            font-size: 16px;
          }
        }
        @media screen and (max-width: 1080px) {
          .post-time {
            margin-bottom: 8px;
            display: block;
          }
          .post-description-wrap {
            margin-bottom: 12px;
          }
        }
        @media screen and (min-width: 1080px) {
          .post-item :global(.post-link) {
            display: grid;
            grid-gap: 0 40px;
            justify-content: space-between;
            grid-template-columns: minmax(100px, 220px) minmax(200px, 515px) minmax(200px, 300px);
          }
          .post-title {
            font-size: 38px;
            margin-bottom: 24px;
          }
          .post-description {
            font-size: 18px;
            max-width: 440px;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

BlogItem.propTypes = {
  post: PropTypes.object.isRequired
};

export { BlogItem };
