import React from "react";
import ContactForm from "./ConctactSectionForm";
import { TitleSection } from "../../components/TitleSection/TitleSectionComponent";

const ContactSectionContent = () => {
  return (
    <React.Fragment>
      <TitleSection count="06." title="contact us" />
      <div className="width-container">
        <hr className="custom-hr" />

        <div className="contact-form-wrap">
          <h3 className="contact-section-title">Let’s discuss your case</h3>
          <ContactForm />
        </div>
      </div>

      <style>{`
        .contact-section-title {
          font-family: Neutrif Pro;
          font-style: normal;
          font-weight: 500;
          line-height: 1.2;
          letter-spacing: -0.02em;
          color: #0005FB;
        }
        @media screen and (min-width: 320px) {
          .contact-section {
            margin: 50px 0 50px;
          }
          .contact-form-wrap {
            margin-top: 25px;
          }
          .contact-section-title {
            font-size: 24px;
          }
        }
        @media screen and (max-width: 1080px) {
          .contact-section-title {
            margin-bottom: 12px;
          }
        }
        @media screen and (min-width: 1080px) {
          .contact-section {
            margin: 200px 0 150px;
          }
          .contact-form-wrap {
            margin-top: 30px;
            display: grid;
            grid-gap: 0px 20px;
            justify-content: space-between;
            grid-template-columns: minmax(200px, 335px) minmax(410px, 630px);
          }
          .contact-section-title {
            font-size: 60px;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

export { ContactSectionContent };
