import React, { useState } from "react";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import PropTypes from "prop-types";
import "../../components/StyledButton/styleButton.css";

const FormItem = Form.Item;

const ConctactSectionForm = ({ form }) => {
  const [isSuccess, setIsSuccess] = useState(false);

  const { getFieldDecorator } = form;

  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        sendMessage(values);
        form.resetFields();
      }
    });
  };

  const sendMessage = values => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...values })
    })
      .then(() => {
        console.log("Form submission success");
        setIsSuccess(true);
      })
      .catch(error => {
        console.error("Form submission error:", error);
      });
  };

  return (
    <React.Fragment>
      <form
        name="contact"
        data-netlify="true"
        onSubmit={handleSubmit}
        className="contact-section-form"
        data-netlify-honeypot="bot-field"
        autoComplete="off"
      >
        <div className="contact-section-input-wrap">
          <FormItem label="Hi! My name is" className="contact-section-label">
            {getFieldDecorator("name", {
              rules: [
                {
                  whitespace: true
                }
              ]
            })(<Input name="name" className="contact-section-input" placeholder="Mike Ross" />)}
          </FormItem>
        </div>
        <div className="contact-section-input-wrap">
          <FormItem label="I need you guys to help me with" className="contact-section-label">
            {getFieldDecorator("help", {
              rules: [
                {
                  whitespace: true
                }
              ]
            })(<Input name="help" className="contact-section-input" placeholder="building R&D office in Ukraine" />)}
          </FormItem>
        </div>
        <div className="contact-section-input-wrap">
          <FormItem label="Please contact me at" className="contact-section-label">
            {getFieldDecorator("email", {
              rules: [
                {
                  required: true,
                  type: "email",
                  whitespace: true,
                  message: "Please enter correct email"
                }
              ]
            })(<Input name="email" className="contact-section-input" placeholder="mike@ross.com" />)}
          </FormItem>
        </div>
        <div className="success-text-and-submit-button">
          {isSuccess && (
            <div>
              <h2 className="success-title">Thank you for contacting us!</h2>
              <p className="success-text">We will come to you shortly.</p>
            </div>
          )}
          <button className="style-button" type="submit">
            send
          </button>
        </div>
      </form>
      <style>{`
      .contact-section-input-wrap {
        position: relative;
      }
      .contact-section-label {
        font-family: Neutrif Pro;
        font-style: normal;
        font-weight: normal;
        line-height: 1.1;
        letter-spacing: -0.02em;
        color: #141418;
        display: block;
      }
      .ant-form-item-label {
        margin-bottom: 10px;
      }
      .contact-section-input {
        width: 100%;
        height: 30px;
        border-bottom: 1px solid #B7B7C7;
        font-family: Neutrif Pro;
        font-style: normal;
        font-weight: normal;
        line-height: 1.1;
        color: #141418;
        outline: none;
      }
      .contact-section-input::placeholder {
        color: #A9A9BF;
      }
      .contact-section-input:focus {
        outline: none;
      }
      .success-text-and-submit-button {
        display: flex;
        justify-content: space-between;
      }
      .success-text-and-submit-button .style-button {
        text-transform: capitalize;
      }
      .success-title {
        font-family: Neutrif Pro;
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 1.1;
        letter-spacing: -0.02em;
        color: #0005FB;
        margin-bottom: 12px;
      }
      .success-text {
        font-family: Neutrif Pro;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 1.1;
        letter-spacing: -0.02em;
        color: #141418;
      }
      .ant-form-explain {
        font-family: Neutrif Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 1.1;
        color: #F33131;
        position: absolute;
        bottom: 4px;
        right: 0;
      }

      @media screen and (min-width: 320px) {
        .contact-section-label {
          font-size: 18px;
        }
        .contact-section-input {
          font-size: 14px;
        }
        .contact-section-input-wrap:not(:last-child) {
          margin-bottom: 12px;
        }
        .success-text-and-submit-button {
          margin-top: 25px;
        }
      }
      @media screen and (max-width: 1080px) {
        .ant-form-item-label {
          margin-bottom: 10px;
        }
      }
      @media screen and (min-width: 1080px) {
        .contact-section-label {
          font-size: 30px;
        }
        .contact-section-input {
          font-size: 18px;
        }
        .contact-section-input-wrap:not(:last-child) {
          margin-bottom: 40px;
        }
        .success-text-and-submit-button {
          margin-top: 40px;
        }
      }
    `}</style>
    </React.Fragment>
  );
};

const ContactForm = Form.create({})(ConctactSectionForm);

export default ContactForm;

ConctactSectionForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
    resetFields: PropTypes.func
  })
};
