import React from "react";
import PropTypes from "prop-types";

const TitleSection = ({ count, title }) => {
  return (
    <React.Fragment>
      <div className="title-section-wrap">
        <div className="title-section-number">{count}</div>
        <h2 className="title-section">{title}</h2>
      </div>
      {/* --- STYLES --- */}
      <style jsx>{`
        .title-section-wrap {
          display: flex;
          flex-direction: row;
          align-items: baseline;
          justify-content: space-between;
        }
        .title-section-number {
          color: #0005fb;
          font-style: italic;
          font-weight: normal;
          font-family: Neutrif Pro;
        }
        .title-section {
          color: #141418;
          line-height: 1.1;
          font-style: normal;
          font-weight: normal;
          letter-spacing: -0.02em;
          font-family: Neutrif Pro;
          text-transform: lowercase;
        }
        @media screen and (min-width: 320px) {
          .title-section {
            font-size: 44px;
          }
          .title-section-number {
            font-size: 28px;
          }
        }
        @media screen and (min-width: 768px) {
          .title-section {
            font-size: 54px;
          }
        }
        @media screen and (min-width: 1200px) {
          .title-section {
            font-size: 144px;
          }
          .title-section-number {
            font-size: 38px;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

export { TitleSection };

TitleSection.propTypes = {
  count: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};
